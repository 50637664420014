@charset "UTF-8";


/*商品説明文の企画テキスト -------------------- */
#wrapper #contents #Detail #main-column #itemDetail-wrap .description .eventinfo {
	letter-spacing:1px;
	line-height:2em;
	font-size: 15px;
	margin-bottom:30px;
}
/*商品説明---------------------------------------------- */
#Detail #itemDetail-wrap .description .iteminfo  {
	letter-spacing:1px;
	line-height:3em;
	font-size: 15px;
	font-family: "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
}
#Detail #itemDetail-wrap .description .iteminfo a {
	font-family: "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
	font-size: 13px;
	color:#FFFFFF;
	background-color:#CCCCCC;
	border-radius:15px;
	padding:5px 20px;
	text-decoration:none;
}
#Detail #itemDetail-wrap .description .iteminfo a:hover {
	text-decoration: none;
	color:#000000;
}
#Detail #itemDetail-wrap .description .iteminfo p {
	line-height:3em;
	margin: 20px 0 30px 0;
}
#Detail #itemDetail-wrap .description .iteminfo .modelsize  {
	width:90%;
}
#Detail #itemDetail-wrap .description .sozai {
	border:solid 2px #CCCCCC;
	padding:20px;
}
#Detail #itemDetail-wrap .description .iteminfo .item-karabari a{
	background-color: #FFFFFF;
}
#wrapper #contents #Detail #main-column #itemDetail-wrap .description .iteminfo .tb-size td {
	line-height:1.5em;
}
#Detail #itemDetail-wrap .description .daimoji{
	font-size:1.4em;
	font-weight:bold;
}
/*商品画像大きさ*/
#Detail #itemDetail-wrap .description .item-image { 
    width:730px;
    height:auto;
	margin-top:15px;
	margin-bottom:30px;
}
/*商品説明サイズ表---------------------------------------------- */
#Detail #itemDetail-wrap .description .iteminfo .tb-size {
	table-layout: fixed;
	font-family: "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
}
#Detail #itemDetail-wrap .description .iteminfo .tb-size td {
	text-align:center;
	font-size:14px;
}
#Detail #itemDetail-wrap .description .iteminfo .tb-size td, th {
    border-bottom: 1px dotted #CCCCCC;
	padding:10px 0;
	}
#Detail #itemDetail-wrap .description .iteminfo .tb-size .saizumei {
	font-weight: bold;
}
#Detail #itemDetail-wrap .description .iteminfo .tanni {
	text-align:right;
	width:96%;
	font-size:10px;
	margin-top:-10px;
}
/*コーデ*/
#Detail #itemDetail-wrap .description .iteminfo .item-ko-de {
	font-size:13px;
	width:100%;
	padding-bottom:30px;
}
.iteminfo .item-ko-de .ko-de-title {
	position: relative;
	top: -20px;
}
.iteminfo .item-ko-de .hidari {
	width:50%;
	float:left;
	position: relative;
	top: -35px;
}
.iteminfo .item-ko-de .migi {
	width:50%;
	float:left;
	position: relative;
	top: -35px;
}
/*商品説明文タブデザイン*/
.cp_tab *, .cp_tab *:before, .cp_tab *:after {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}
.cp_tab {
	margin: 0.2em auto;
}
.cp_tab > input[type='radio'] {
	margin: 0;
	padding: 0;
	border: none;
	border-radius: 0;
	outline: none;
	background: none;
	-webkit-appearance: none;
	        appearance: none;
	display: none;
}
.cp_tab .cp_tabpanel {
	display: none;
}
.cp_tab > input:first-child:checked ~ .cp_tabpanels > .cp_tabpanel:first-child,
.cp_tab > input:nth-child(3):checked ~ .cp_tabpanels > .cp_tabpanel:nth-child(2),
.cp_tab > input:nth-child(5):checked ~ .cp_tabpanels > .cp_tabpanel:nth-child(3),
.cp_tab > input:nth-child(7):checked ~ .cp_tabpanels > .cp_tabpanel:nth-child(4),
.cp_tab > input:nth-child(9):checked ~ .cp_tabpanels > .cp_tabpanel:nth-child(5),
.cp_tab > input:nth-child(11):checked ~ .cp_tabpanels > .cp_tabpanel:nth-child(6) {
	display: block;
}
.cp_tab > label {/*タブすべて*/
	position: relative;
	display: inline-block;
	padding: 3px 15px;
	cursor: pointer;
	border: 1px solid transparent;
	border-bottom: 0;
	background: #E5E5E5;
	border-radius: 6px 6px 0 0;
	opacity: 0.7;
}
.cp_tab > label:hover,
.cp_tab > input:focus + label {
	color:#0066cc;
}
.cp_tab > input:checked + label {/*選択タブ*/
	margin-bottom: -1px;
	border-color: #cccccc;
	border-bottom: 1px solid #ffffff;/*背景色と同じ*/
	background: #FFFFFF;
	opacity: 1;
}
.cp_tab .cp_tabpanel {
	padding: 0.5em 1em;
	border-top: 1px solid #cccccc;
}
	
/*ディティール画像*/
.item-details {
    margin: 0;
    padding: 0;
	width:100%;
	vertical-align:top;
	padding-bottom:30px;
    }
.item-details img{
    width: 46%;
	padding-right:2%;
    flex-wrap:wrap;
    }

/*商品ページカラバリ*/
.iteminfo .item-karabari{
	list-style:none;
	display:inline-block;
	margin-top:-15px;
}
.item-karabari img {
	width:70px;
	height:auto;
}
.item-karabari li{
	display:inline-block;
	margin-right:10px;
}
.item-karabari a{
	background-color: #FFFFFF;
}
.osirase {
	font-size: 14px;
	width:980px;
	margin: 10px auto;
	font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
	padding:10px;
	line-height:1.5em;
	background-color: #F5F5F5;
	border: 1px solid #CCCCCC;
}
/*価格部分の線消し*/
#Detail #main-column #itemDetail-wrap #itemDetail-cont .shopping_form #pi_cart .price .price td, th {
border-style:none;
}
/*サイズカテゴリ*/
#Journal .journal-cont .s-hyou {
	font-size: 14px;
}
#Journal .journal-cont .s-hyou td{
	padding:15px 0px;
}
#Journal .journal-cont .s-hyou .back-color {
	background-color: #E5E5E5;
}
#JournalList .journal-cont .s-hyou {
	font-size: 14px;
}
#JournalList .journal-cont .s-hyou td{
	padding:15px 0px;
}
#JournalList .journal-cont .s-hyou .back-color {
	background-color: #E5E5E5;
}


/*お買い物ガイド*/
.guide-hyou {
	width:50%;
	margin:10px 0;
}
.guide-hyou td, .guide-hyou th {
	padding:5px;
	border: 1px solid  #CCCCCC;
}
 #JournalList .guide-list {
	list-style-type: none;
	text-indent:-1em;
	padding-left:1em;
}
 #JournalList .guide-list li {
	position: relative;
	padding-left:1em;
}
 #JournalList .guide-list li::after {
	display: block;
	content: '';
	position: absolute;
	top: 1em;
	left: -1em;
	width: 4px;
	height: 4px;
	border-right: 1px solid #666;
	border-bottom: 1px solid #666;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
 #Journal .guide-list {
	list-style-type: none;
	text-indent:-1em;
	padding-left:1em;
}
 #Journal .guide-list li {
	position: relative;
	padding-left:1em;
}
 #Journal .guide-list li::after {
	display: block;
	content: '';
	position: absolute;
	top: 1em;
	left: -1em;
	width: 4px;
	height: 4px;
	border-right: 1px solid #666;
	border-bottom: 1px solid #666;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.guide-hyou td:nth-child(odd) {
  background: #F0F0F0;
}
.red {
	color:#FF0000;
}
.oomoji{
	font-size:16px;
}
#Journal .journal-cont h3 {
	background-color:#F5F5F5;
	font-size:18px;
	padding:3px 3px 3px 15px;
	margin-bottom:20px;
}
#JournalList .journal-cont h3 {
	background-color:#F5F5F5;
	font-size:18px;
	padding:3px 3px 3px 15px;
	margin-bottom:20px;
}
/*お買い物ガイド戻るボタン*/
#Journal .guide-modoru {
	text-align:right;
	padding-top:10px;
}
#Journal .guide-modoru a {
	text-decoration: underline;
}
#Journal .guide-modoru a:hover{
	text-decoration: none;
}
#JournalList .guide-modoru {
	display:none;
}
/*会員登録ボタン*/
.square_btn {
    display: inline-block;
    padding: 0.3em 3em;
    border: solid 2px #CCCCCC;
    border-radius: 3px;
    transition: .4s;
	text-align:center;
    background: #CCCCCC;
	font-size:16px;
    color: white;
	left: 50%;
}
.square_btn:hover {
    background: white;
    color: #CCCCCC;
}
/*お買い物ガイドメニュー*/
#JournalList .journal-cont .guide-box {
	width: 45%;
	border: 1px solid #CCCCCC;
	margin: 5px;
	display:inline-block;
	padding:5px;
	vertical-align: top; 
	
}
#JournalList .journal-cont .guide-box .box-title {
	position: relative;
	font-size: 16px;
	padding:8px;	
	background-color:#CCCCCC;
	padding-left:1.5em;
}
#JournalList .journal-cont .guide-box .box-title .box-black {
	position: absolute;
	top: 0;
	bottom: 0;
	left:0;	
	width: 5px;
	background: #000000;
}
#JournalList .journal-cont .guide-box .guide-link {
	margin:10px 0;
	padding-left:1.5em;
	background: url(https://handmade-wafu.com/pic-labo/icon-maruyaji.jpg) no-repeat;
	background-size:10px 10px;
	background-position:5px 10px;
	margin-top:3px;
}
#JournalList .journal-cont .guide-box .guide-link a {
	display:inline-block;
}
/*オーダーサイズ表*/
.journal-cont .order-tbl {
	border-collapse: collapse;
	margin:30px 0;
}
.journal-cont .order-tbl th, .journal-cont .order-tbl td{
	border: 1px solid  #CCCCCC;
	padding:5px;
}
.journal-cont .order-tbl tr:nth-child(odd) {
	background: #eee;
}
/*洗濯表記記号ページ*/
.hyouki {
	width:100%;
	height:auto;
	position:relative;
}
.hyouki td {
	text-align:center;
	background-color:#FFFFFF;
	border: 1px solid  #CCCCCC;
	padding:5px;
	vertical-align: top;
}
.hyouki tr td:nth-of-type(1) { width:15%;}
.hyouki tr td:nth-of-type(2) { width:20%;}
.hyouki tr td:nth-of-type(3) { width:65%;}
.hyouki .text {
	text-align: left;
	vertical-align: top;
}
.hyouki .title {
	font-size: 16px;
	text-align: left;
	vertical-align: top;
}
.hyouki .left-retu {
	vertical-align: top;
	display: inline-block;
	width:45%;
	padding-right:10px;
}
.hyouki .right-retu {
	vertical-align: top;
	display: inline-block;
	width:45%;
	text-align:right;
}
.hyouki .left-retu .hyou1,  .hyouki .left-retu .hyou2,  .hyouki .left-retu .hyou3 {
	margin-bottom:10px;
	width:100%;
}
.hyouki .right-retu .hyou4,  .hyouki .right-retu .hyou5,  .hyouki .hyou6, .right-retu .hyouki .hyou7 {
	margin-bottom:10px;
	width:100%;
}
/*よくある質問*/
.guide-q {
	font-weight: bold;
	text-indent:-1.5em;
	padding-left:1.5em;
	background-image:url(https://handmade-wafu.com/pic-labo/icon-q.jpg);
	background-repeat: no-repeat;
	background-size:15px 15px;
}
.guide-a {
	font-weight: bold;
	text-indent:-1.5em;
	padding-left:1.5em;
	background-image:url(https://handmade-wafu.com/pic-labo/icon-a.jpg);
	background-repeat: no-repeat;
	background-size:15px 15px;
}
.question{
	padding-bottom:40px;
}
.question-list {
	border: 1px solid #CCCCCC;
	margin-bottom:30px;
	padding:5px;
}
.question-title {
	position: relative;
	background-color:#F5F5F5;
	padding-left:1.8em;
}
.box-black {
	position: absolute;
	top: 0;
	bottom: 0;
	left:0;	
	width: 5px;
	background: #000000;
}
.question-link {
	margin:10px 0;
	padding-left:1.8em;
	background: url(https://handmade-wafu.com/pic-labo/icon-q.jpg) no-repeat;
	background-size:15px 15px;
	background-position:5px 8px;
}
.question-link a {
	display:inline-block;
}
.senn {
	border-top: 1px dotted #CCCCCC;
	font-size: 65%;
}
.hannbaikai-hyou {
	width:100%;
	margin:10px 0;
}
.hannbaikai-hyou td, .hannbaikai-hyou th {
	padding:10px;
	border: 1px solid  #CCCCCC;
	text-align:center;
}
/*sizeの測り方ページ*/
.size-measure {
	width:100%;
}
.size-measure .size-item {
	width:100%;
	display: inline-block;
	margin-bottom:40px;
}
.size-measure .size-item  img{
	width:60%;
	margin-right:5%;
}
.size-measure .size-place{
	width:35%;
	display: inline-block;
	padding-top:30px;
}
.size-place dl {
	line-height:1.5em;
}
.size-place dt {
	font-weight: bold;
}
.size-place dd {
	margin-bottom:20px;
}
/*お試し生地*/
.fab-sample {
	font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', sans-serif;
 *font-family: 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
 
 }
 
.fab-sample .sample-image {
	width:100%;
	margin:0;
	padding:0;
}
.fab-sample .sample-image img {
	width:100%;
	height:auto;
	text-align:center;
	margin: 0px auto;
}
.fab-sample .sample-image li {
	width:160px;
	font-size: 12px;	
	border: 1px solid #CCCCCC;
	padding: 5px 5px;
	margin: 0px 0px 10px 0px;
	list-style: none;
	display:inline-block;
	vertical-align:top;
	
}
.fab-sample .sample-image .color-name {
	font-weight: bold;
}
  
/*トップイメージ*/
.flex0 {
	display:none;
}
.flex1 {
    display:none;
}
/*商品ページtitle*/
    .product__title{
		font-size: 14px!important;}

/*商品ページカラバリ*/
#main-column .description .iteminfo .color-if iframe{
    width:100%;
    height:220px;}
.color-if iframe{
    width:100%;
    height:220px;}
	
	
/*newカテゴリー*/
#wrapper #contents #main-column .header_area .newitem-ct{
    width:100%;
    height:3800px;}
	
	
	
	
/*連休のお知らせ*/
#wrapper #contents #main-column #Journal .journal-cont .rennkyuu-tbl {
	border-collapse: collapse;
	margin:30px 0;
}
#wrapper #contents #main-column #Journal .journal-cont .rennkyuu-tbl th, #wrapper #contents #main-column #Journal .journal-cont .rennkyuu-tbl td{
	border: 1px solid  #CCCCCC;
	padding:5px;
}
#wrapper #contents #main-column #Journal .journal-cont .rennkyuu-tbl tr:first-child {
	background: #eee;
}
#wrapper #contents #main-column #Journal .journal-cont .rennkyuu-tbl td {
	text-align: center;
}

/*サイズ表*/
#Detail #itemDetail-wrap .description .iteminfo .tb-size td {
	text-align:center;
	font-size:2.5vw;
	margin:10px 0;
}
/*商品説明文リンク*/	
#Detail #itemDetail-wrap .description .iteminfo a { 
	font-size:80%;
	white-space: nowrap;
}
#Detail #itemDetail-wrap .description .iteminfo .sozai img { 
	width:15%;
	height:auto;
	margin:10px 0;
}
#Detail #itemDetail-wrap .description .iteminfo .modelsize  {
	width:100%;
}
/*商品説明文*/
#Detail #itemDetail-wrap .description .iteminfo p  {
	line-height:1.5em;
}


/*お客様の声*/
#wrapper #contents #main-column .rating {
	width:100%;
	word-break: break-all;
}

/*商品ページ画像*/
#wrapper .shopping_form .itemThumb-wrap .itemThumb-main .mainImg{display:none!important;}
#wrapper .shopping_form .itemThumb-wrap .itemThumb {display:none;}
/*商品ページカラバリ*/
#main-column .description .iteminfo .color-if iframe{
    width:100%;
    height:160px;}
.color-if iframe{
    width:100%;
    height:160px;}
/*newカテゴリー*/
#wrapper #contents #main-column .header_area .newitem-ct{
    width:100%;
    height:3000px;}

@media (max-width: 320px) {
	#header-inner #logo img	{margin-left: 30px;}

}

.oneArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.fukidasi{
  position: relative;
  padding: 20px;
  background-color: #000;
  font-size: 16px;
  color: #ffffff;
  border-radius: 10px;
}

.fukidasi::before{
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  right: -15px;
  top: 22px;
  border-left: 25px solid transparent;
  border-top: 25px solid #000;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

.fukidasi::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  right: -25px;
  top: 40px;
  border-left: 25px solid transparent;
  border-top: 25px solid #ffffff;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.fukiimg{
	margin-top:10px;
	margin-left:15px;
	width:auto;
	padding:5px;
}
.fukiimg img{
	margin:5px;
	width:100px;
}
/*カラバリ コーディネート*/
.otherinformation {
    position: relative;
	padding:0;
        margin:0;	
}
.otherinformation p {
    line-height:11px;
    margin:0;
    padding:0;	
}
.otherinformation ul {
	list-style: none;
	padding:0px;
        margin-bottom:0px;
	text-align: left;
}
.otherinformation li {
	list-style: none;
	display: inline-block;
	padding:0px;
        margin-bottom:0px;
	text-align: left;
}
.otherinformation img {
	width:100px;
	height:132px;	
}

@media screen and (max-width: 380px) {
.otherinformation {width:100%;}
.otherinformation ul {width:100%;
                     height:100%}
.otherinformation li {width:25%;
                     height:100%}
.otherinformation img {width:100%;
                     height:100%}
}